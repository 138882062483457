<template>
  <div>
    <base-subheading :class="{ 'display-1': large_header }">
      {{ $t(path_in_translations + ".title") }}
    </base-subheading>

    <div class="mt-5" v-if="$te(path_in_translations + '.p')">
      <p v-for="(item, i) in $t(path_in_translations + '.p')" :key="i">
        {{ $t(path_in_translations + ".p[" + i + "]") }}
      </p>

      <ul v-if="$te(path_in_translations + '.list')" class="pb-4">
        <li v-for="(item, i) in $t(path_in_translations + '.list')" :key="i">
          {{ $t(path_in_translations + ".list[" + i + "]") }}
        </li>
      </ul>

      <div v-if="$te(path_in_translations + '.p_after')">
        <p v-for="(item, i) in $t(path_in_translations + '.p_after')" :key="'p2' + i">
          {{ $t(path_in_translations + ".p_after[" + i + "]") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path_in_translations: {
      type: String,
      default: "",
    },
    large_header: Boolean,
  },
};
</script>

<style scoped>
li {
  line-height: 2em;
}
</style>